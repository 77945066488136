<template>
    <layout-heading>
        <portal to="title">
            <router-link to="/nft">
                <img src="../../../assets/images/icons/smart-license.svg" alt="">
                <span>Smart License</span>
            </router-link>
        </portal>
        <template #heading-cta>
            <ContractDropdown v-if="smartData"
                              @change-contract="reset"
                              :name="smartData.name"
                              :address="address"
                              :network="network"
            />
        </template>

        <p>
            Smart License contracts allow you to accept payments, and optionally specify multiple recipients with
            their shares.
            <br>
            <router-link to="/smartd">Create Smart License</router-link>
        </p>

        <ContractLoader v-if="!smartData"
                        name="Smart License"
                        path="smart"
                        features="smart-license"
                        :deployer="deployerAddress"
                        :common="commonContracts"/>

        <div v-else-if="happ" class="row">
            <div class="col-md-5 making-payments">
                <h3>Making Payments</h3>
                <p>You can make payments using the options below, or share and embed the payment links.</p>
                <h5>Ether</h5>
                <div class="dumbapp-with-sharing">
                    <DumbappPopover :instance="dumbapps.payEth" label="Pay Ether"/>
                    <router-link :to="shareLink(dumbapps.payEth)">Share or Embed</router-link>
                </div>
                <h5>Tokens</h5>
                <p>Specify a token you want payments with by either searching for it, or entering a token address.</p>
                <ContractLoader
                    :load-click="tokenLoaded"
                    features="erc20"
                    :network="happ.network.networkId"
                    hide-recents
                    />
                <div v-if="tokenError">
                    <p class="error">{{tokenError}}</p>
                </div>
                <ProgressCircle small v-else-if="$asyncComputed.token.updating || $asyncComputed.tokenDumbapp.updating" />
                <div v-else-if="tokenDumbapp" class="dumbapp-with-sharing">
                    <DumbappPopover :instance="tokenDumbapp" :label="'Pay ' + token.name"/>
                    <router-link :to="shareLink(tokenDumbapp)">Share or Embed</router-link>
                </div>
            </div>
            <div v-if="smartData && smartData.recipients" class="card col-md-7">
                <div class="card-body">
                    <h3>Recipients</h3>
                    <p>
                        The following addresses receive a part of any payments made to this contract.
                    </p>

                    <div class="table-responsive-md">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Address</th>
                                <th>Percentage</th>
                            </tr>
                            </thead>
                            <tbody class="pools">
                            <tr v-for="rec in smartData.recipients">
                                <td class="ellipsize-address">
                                    <Clipboard :address="rec.address" :network="network"/>
                                </td>
                                <td>
                                    <TokenAmount :decimals="8" symbol="%" :value="rec.percentage"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </layout-heading>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import ContractDropdown from "@/views/happs/components/ContractDropdown";
import ContractLoader from "@/views/happs/components/ContractLoader";
import HappMixin from "@/views/happs/Happ";
import {loadSmartLicense} from "@/views/happs/smart-license/lib/smartlib";

export default {
    components: {ProgressCircle, DumbappPopover, Clipboard, TokenAmount, ContractLoader, ContractDropdown},
    mixins: [HappMixin],
    data() {
        return {
            type: "smart",
            deployerAddress: "0x2e2A7a756A80F0A0fc4B7bFe1CBF2e8f2650a36E",
            commonContracts: [],
            tokenAddress: null,
            tokenError: null
        };
    },
    asyncComputed: {
        async smartData() {
            if (this.happ) {
                let data = await loadSmartLicense(this.happ);

                if (this.loggedIn) {
                    this.api.addToContractHistory(
                        this.$route.fullPath,
                        "Smart License happ",
                        this.happ.address,
                        this.happ.network.networkId
                    );
                }
                return data;
            }
            return null;
        },
        async dumbapps() {
            if (this.happ && this.happ.contractId) {
                return this.api.getSmartLicenseCodes(this.happ.contractId)
            }
            return {};
        },
        async token() {
            this.tokenError = null;
            if (this.happ && this.tokenAddress) {
                const address = this.tokenAddress.address || this.tokenAddress;

                let res;

                try {
                    res = await this.api.getContractId(address, this.happ.network.networkId);
                } catch (err) {
                    console.error(err);
                }

                if (!res) {
                    this.tokenError = "Could not load the token contract.";
                    return;
                }

                return this.api.getTokenData(res.id);
            }
            return null;
        },
        async tokenDumbapp() {
            if (this.happ && this.token) {
                return this.api.getSmartLicenseTokenDumbapp(this.happ.contractId, this.token.address);
            }
            return null;
        }
    },
    methods: {
        reset() {
            this.$router.push('/smart');
        },
        shareLink(dumbapp) {
            return `/${dumbapp?.shortcode}/sharing`;
        },
        tokenLoaded(data) {
            this.tokenAddress = data;
            console.log('tokenAddress', this.tokenAddress);
        }
    }
}
</script>

<style scoped lang="scss">
.ellipsize-address {
    font-size: 0.9em;

    ::v-deep( .clipboard-text ) {
        max-width: none;
        @media screen and (max-width: 540px) {
            max-width: 140px;
        }
    }
}

.dumbapp-with-sharing {
    display: flex;
    align-items: center;

    ::v-deep( .dumbapp-popover ) {
        margin-right: 10px;
    }
}

.making-payments {
    padding-top: 25px;

    ::v-deep( .contract-container ) {
        margin-bottom: 5px;
    }
}
</style>
