import {Batcher} from "@/lib/eth/Batcher";
import BigNumber from "bignumber.js";
import abi from "../../../../assets/data/SmartLicense.abi.json";

/**
 *
 * @param {Happ} happ
 * @return {Promise<object>}
 */
export async function loadSmartLicense(happ) {
    let batcher = new Batcher(happ.web3);

    batcher.setContract(abi, happ.address)
        .add('name')
        .add('getPaymentRecipients')
        .add('getPercentages')
        .add('balanceRecipient');

    let res = await batcher.execute();

    let recipients = [];

    let i = 0;
    let remainder = new BigNumber(98e8);
    for (let address of res.getPaymentRecipients) {
        let percentage = res.getPercentages[i];

        remainder = remainder.minus(percentage);

        recipients.push({
            address,
            percentage
        });

        ++i;
    }

    recipients.push({
        address: res.balanceRecipient,
        percentage: remainder
    });

    res.recipients = recipients;

    return res;
}
